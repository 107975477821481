import { Controller } from "stimulus"
import $ from 'jquery'
import select2 from 'select2'

export default class extends Controller {
  static targets = []

  initialize() {
    document.addEventListener('turbolinks:before-cache', () => {
      document.querySelectorAll('.participant-status-select2, .r4-eligibility-select2, .mrn-status-select2, .r4-admin-status-select2, .r4-consent-status-select2, .study-tracker-status-select2').forEach((select2) => {
        $(select2).select2()
        $(select2).select2('destroy')
      })
    })
  }
  connect() {
    $('.participant-status-select2').select2()
    $('.r4-eligibility-select2').select2()
    $('.mrn-status-select2').select2()
    $('.r4-admin-status-select2').select2()
    $('.r4-consent-status-select2').select2()
    $('.study-tracker-status-select2').select2()
  }
}
