import { Controller } from "stimulus"
import $ from 'jquery'
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['r4SynchParticipantFrom', 'r4SynchParticipantCancelLink']

  initialize() {
  }

  connect() {
    var controller, selects, r4SynchParticipanttFrom, r4SynchParticipantCancelLink
    controller = this
    r4SynchParticipanttFrom = this.r4SynchParticipantFromTarget
    r4SynchParticipantCancelLink= this.r4SynchParticipantCancelLinkTarget

    M.updateTextFields()

    r4SynchParticipanttFrom.addEventListener('ajax:beforeSend', function (event) {
      $('#r4-participant-synch .loading').removeClass('hide')
    })

    r4SynchParticipanttFrom.addEventListener('ajax:success', function (event) {
      const [data, status, xhr] = event.detail;
      var modal = document.querySelector('.modal');
      var instance = M.Modal.getInstance(modal);
      instance.close();
      Turbolinks.visit(location.toString())
    })

    r4SynchParticipanttFrom.addEventListener('ajax:error', function (event) {
      const [data, status, xhr] = event.detail;
      if (xhr.status != 401) {
        var modalContent = document.querySelector('#participant_edit .modal-content');
        modalContent.innerHTML = xhr.responseText
      }
    })

    r4SynchParticipanttFrom.addEventListener('ajax:complete', function (event) {
      $('#r4-participant-synch .loading').addClass('hide')
    })

    r4SynchParticipantCancelLink.addEventListener('click', function (event) {
      event.preventDefault()
      document.querySelectorAll('.modal').forEach((modal) => {
        var instance = M.Modal.getInstance(modal)
        if (instance) {
          instance.close()
        }
      });
    });
  }
}