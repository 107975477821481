// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

require("trix")
require("@rails/actiontext")

import "controllers"
import 'materialize-css/dist/js/materialize';
import $ from 'jquery';
import select2 from 'select2';
// import 'select2/dist/css/select2.css';

document.addEventListener('turbolinks:load', () => {
  M.Modal._count = 0;

  var modals = document.querySelectorAll('.modal')
  M.Modal.init(modals, { dismissible: false })

  M.updateTextFields()

  document.querySelectorAll('.dropdown-trigger').forEach((dropdown) => {
    M.Dropdown.init(dropdown, { constrainWidth: false });
  });

  document.querySelectorAll('.datepicker').forEach((datepicker) => {
    M.Datepicker.init(datepicker);
  });
});

document.addEventListener('turbolinks:before-cache', () => {
  document.querySelectorAll('select').forEach((select) => {
    var instance = M.FormSelect.getInstance(select);
    if (instance) {
      instance.destroy();
    }
  });

  document.querySelectorAll('.modal').forEach((modal) => {
    var instance = M.Modal.getInstance(modal);
    if (instance) {
      instance.destroy();
    }
  });
});
