import { Controller } from "stimulus"
import Rails from '@rails/ujs'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['adminStudyParticipantNewEmpiLookupUrl', 'registerParticipantForm', 'editParticipantUrl']

  initialize() {
  }

  connect() {
    var controller, registerParticipantForm
    controller = this

    if (this.hasRegisterParticipantFormTarget) {
      registerParticipantForm = this.registerParticipantFormTarget

      registerParticipantForm.addEventListener('ajax:beforeSend', function (event) {
        var loading
        loading = registerParticipantForm.querySelector('.loading')
        loading.classList.remove('hide')
      })

      registerParticipantForm.addEventListener('ajax:success', function (event) {
        const [data, status, xhr] = event.detail
        Turbolinks.visit(location.toString())
      });

      registerParticipantForm.addEventListener('ajax:complete', function (event) {
        var loading
        loading = registerParticipantForm.querySelector('.loading')
        loading.classList.add('hide')
      });

      registerParticipantForm.addEventListener('ajax:error', function (event) {
        const [data, status, xhr] = event.detail
        if (xhr.status != 401) {
          console.log('Kaboom!')
        }
      });
    }
  }

  newEmpi(event) {
    event.preventDefault()
    var controller, currentTarget, adminStudyParticipantNewEmpiLookupUrl
    controller = this
    currentTarget = event.currentTarget
    adminStudyParticipantNewEmpiLookupUrl = this.adminStudyParticipantNewEmpiLookupUrlTarget.href
    Rails.ajax({
      url: adminStudyParticipantNewEmpiLookupUrl,
      type: 'GET',
      success: function(data) {
        var modalContent = document.querySelector('#new_empi_lookup .modal-content')
        modalContent.innerHTML = new XMLSerializer().serializeToString(data)
        controller.setupNewEmpiForm()
      },
      error: function(event, status, xhr, error) {
        if (xhr.status == 401) {
          Turbolinks.visit(location.toString())
        }
      }
    })
   return
  }

  setupNewEmpiForm () {
    var controller, selects, empiLookupForm, newEmpiCancelLink
    controller = this
    var elems = document.querySelectorAll('.datepicker')
    var options = { format: "yyyy-mm-dd", yearRange: [1900,2022] }
    var instances = M.Datepicker.init(elems, options)
    var elems = document.querySelectorAll('select')
    var instances = M.FormSelect.init(elems, options)
    M.updateTextFields()

    empiLookupForm = document.querySelector('#empi-lookup-form')
    empiLookupForm.addEventListener('ajax:beforeSend', function (event) {
      $('#empi-lookup-results').empty()
      $('#empi_lookup .loading').removeClass('hide')
    })

    empiLookupForm.addEventListener('ajax:success', function (event) {
      const [data, status, xhr] = Array.from(event.detail)
      $('#empi-lookup-results').html(data.body.innerHTML)
    })

    empiLookupForm.addEventListener('ajax:error', function (event) {
      $('#empi-lookup-results').empty()
      $("#empi-lookup-results").append('<div>Error calling EMPI</div>')
    })

    empiLookupForm.addEventListener('ajax:complete', function (event) {
      $('#empi_lookup .loading').addClass('hide')
    })

    empiLookupForm.querySelector('#first_name').addEventListener('input', function(event) {
      $('#empi-lookup-results').empty()
    })

    empiLookupForm.querySelector('#last_name').addEventListener('input', function(event) {
      $('#empi-lookup-results').empty()
    })

    empiLookupForm.querySelector('#birth_date').addEventListener('input', function(event) {
      $('#empi-lookup-results').empty()
    })

    empiLookupForm.querySelector('#gender').addEventListener('change', function(event) {
      $('#empi-lookup-results').empty()
    })

    addEventListener('ajax:complete', function (event) {
      $('#empi_lookup .loading').addClass('hide')
    })

    newEmpiCancelLink = document.querySelector('.new-empi-cancel-link')
    newEmpiCancelLink.addEventListener('click', function (event) {
      event.preventDefault()
      document.querySelectorAll('.modal').forEach((modal) => {
        var instance = M.Modal.getInstance(modal)
        if (instance) {
          instance.close()
        }
      });
    });
  }

  edit (event) {
    event.preventDefault()
    var controller, currentTarget, editParticipantUrl
    controller = this
    currentTarget = event.currentTarget
    editParticipantUrl = this.editParticipantUrlTarget.href
    Rails.ajax({
      url: editParticipantUrl,
      type: 'GET',
      success: function(data) {
        var modalContent = document.querySelector('#participant_edit .modal-content')
        modalContent.innerHTML = new XMLSerializer().serializeToString(data)
      },
      error: function(event, status, xhr, error) {
        if (xhr.status == 401) {
          Turbolinks.visit(location.toString())
        }
      }
    })
   return
  }
}