import { Controller } from "stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ['empiLookupResultFrom', 'empiLookupResultFirstNameEmpi', 'empiLookupResultLastNameEmpi', 'empiLookupResultBirthDateEmpi', 'empiLookupResultGenderEmpi']

  initialize() {
  }

  connect() {
    var controller, empiLookupResultFrom, firstName, lastName, birthDate, gender
    controller = this
    empiLookupResultFrom = this.empiLookupResultFromTarget

    empiLookupResultFrom.addEventListener('ajax:before', function (event) {
      firstName = document.querySelector('#empi_lookup #first_name').value
      controller.empiLookupResultFirstNameEmpiTarget.value = firstName
      lastName = document.querySelector('#empi_lookup #last_name').value
      controller.empiLookupResultLastNameEmpiTarget.value  = lastName
      birthDate = document.querySelector('#empi_lookup #birth_date').value
      controller.empiLookupResultBirthDateEmpiTarget.value = birthDate
      gender = document.querySelector('#empi_lookup #gender').value
      controller.empiLookupResultGenderEmpiTarget.value = gender
    });

    empiLookupResultFrom.addEventListener('ajax:success', function (event) {
      const [data, status, xhr] = event.detail
      var modal = document.querySelector('.modal')
      var instance = M.Modal.getInstance(modal)
      instance.close()
      Turbolinks.visit(location.toString())
    });

    empiLookupResultFrom.addEventListener('ajax:error', function (event) {
      const [data, status, xhr] = event.detail
      if (xhr.status != 401) {
        var empiPatient = empiLookupResultFrom.closest('.empi_patient')
        var errors = document.querySelector('.empi_errors')
        empiPatient.classList.add('invalid')
        errors.innerHTML = xhr.responseText
      }
    });
  }
}